import { Component } from 'react';
import {defaultZoomParams} from '../constants.js';
import np from 'nested-property';

export class Controller extends Component {
  constructor(props){
    super(props);
    this.state = {};
    
    this.fixDocument = this.fixDocument.bind(this);
    this.resetZoom = this.resetZoom.bind(this);
    this.determinOverlay = this.determinOverlay.bind(this);
  }

  componentDidMount() {
    this.fixDocument();
    this.determinOverlay();
  }

  componentWillUnmount() {
    this.resetZoom();
    this.props.setOverlay("");
    
  }

  resetZoom () { 
    this.props.setZoom( defaultZoomParams );
  }


  fixDocument(){
      const {pathname} = this.props.location;
      document.getElementsByTagName( 'html' )[0].setAttribute( 'class', pathname==="/" ? '' : 'no-scroll' ); 
    }

  determinOverlay(){
    const {match} = this.props;
    // overlay schliessen
    this.props.setOverlay( np.get(match,"params.section") === "studio" ? np.get(match,"params.slug") : "" );
  }


  componentDidUpdate(prevProps, prevState) {
    this.fixDocument();
    if( prevProps.location !== this.props.location ){

      const {pathname} = this.props.location;
      const {match} = this.props;

      this.props.setDimensions();
      this.determinOverlay();



      // zoom einstellen
      let zoom = this.props.zoom;
      if(pathname==="/"){ 
        zoom.in=false; 
      }


      // reset zoom when on studio or projekte
      if( np.get(match,"params.section") === "studio" || np.get(match,"params.section") === "etcetera" ){
        zoom = defaultZoomParams;
      }

      // hide page while zoomout
      if( np.get(prevProps.match,"params.section") === "projekte" && pathname === "/" ){
        zoom.hidepage = true;
      }


      this.props.setZoom(zoom);
      document.getElementsByTagName( 'html' )[0].setAttribute( 'class', zoom.in?'no-scroll':'' ); 
      }
  }


  render() { return null; }

}
