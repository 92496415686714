export const dataUrl = "https://lemmenmazzei.ch/api" ;

export const transDuration = 1000;

export const defaultZoomParams = {
  in: false,
  hidepage: false,
  position: 1,
  slug:"",
  itemTop: 0,
  scrollTop: 0,
};