import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import navToggler from '../static/nav-toggler.svg';
import np from 'nested-property';

const getInitialSection = match =>{
  let sec = np.get(match,"params.section");
  if(sec === undefined){
    sec = "projekte";
  }
  return sec;
}

export class Navigation extends Component {
  constructor(props){
    super(props);
    this.state = {
      navigation: false,
      section: getInitialSection(this.props.match),
    }
    this.setNavigation = (bool)=>{ this.setState({ navigation:bool }); };
    this.setSection = (sec)=>{ this.setState({ section:sec }); };
  }
  componentDidUpdate(prevProps, prevState) {
    if( prevProps.location.key !== this.props.location.key ){
      this.setState({navigation:false})
    }
  }

  render() {
    const {section} = this.state;
    return (
      <React.Fragment>

        <div className="m-Logo lead">
          <Link to="/" className="no-decoration">Lemmen Mazzei</Link>
        </div>
          
        <div 
          className="m-NavigationButton clickable pt-0"
          onClick={ ()=>{ this.setNavigation(!this.state.navigation) }}
          >
          <div className="m-NavigationButtonInner"><NavToggler active={this.state.navigation} /></div>
        </div>
      

        <CSSTransition
          in={this.state.navigation}
          timeout={300}
          classNames="fade"
          mountOnEnter
          unmountOnExit
          onEnter={()=>this.setSection(getInitialSection(this.props.match))}
          onExited={()=>this.setSection(getInitialSection(this.props.match))}
          >
            <div className="m-NavigationBody bg-mobile">
              
              <ul className="list-unstyled lead">
                <li>
                  <div onClick={()=>this.setSection("projekte")} className="clickable no-decoration">Projekte</div>
                  <CSSTransition in={section==="projekte"} timeout={300} unmountOnExit exit={false} mountOnEnter classNames="fade" >
                    <ul className="list-unstyled m-NavigationLVL2">{this.props.data.projekte.map(e=><NavRow key={e.slug} section="projekte" {...e}/>)}</ul>
                  </CSSTransition>
                </li>
                <li>
                  <div onClick={()=>this.setSection("studio")} className="clickable no-decoration">Studio</div>
                  <CSSTransition in={section==="studio"} timeout={300} unmountOnExit exit={false} mountOnEnter classNames="fade" >
                    <ul className="list-unstyled m-NavigationLVL2">{this.props.data.studio.map(e=><NavRow key={e.slug} section="studio" {...e}/>)}</ul>
                  </CSSTransition>
                  </li>
                <li>
                  <div onClick={()=>this.setSection("etcetera")} className="clickable no-decoration">Et cetera</div>
                  <CSSTransition in={section==="etcetera"} timeout={300} unmountOnExit exit={false} mountOnEnter classNames="fade" >
                    <ul className="list-unstyled m-NavigationLVL2">{this.props.data.etcetera.map(e=><NavRow key={e.slug} section="etcetera" {...e}/>)}</ul>
                  </CSSTransition>
                  </li>
              </ul>

            </div>
          </CSSTransition>
      </React.Fragment>
    );
  }
}





export const NavToggler = props => {
  const style = {
    width: "200%",
    height: "100%",
    display: "block",
    transform: props.active ? "translate(-50%)" : "translate(0)" ,
  } 
  return <img src={navToggler} alt="" style={style} />
};




const NavRow = (props)=>(
  <li>
    <Link to={"/"+props.section+"/"+props.slug} className="d-block no-decoration">
        {props.title}
    </Link>
  </li>
);

