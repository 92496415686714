import React, { Component } from 'react';

export class Image extends Component {
  constructor(props){
    super(props);
    this.state = {
      there:false,
    }
  }
  render() {
    const {image,style,className} = this.props;
    const {there} = this.state;
    return (
      <img 
        src={image.urlDesktop} 
        alt={image.title} 
        width={image.width} 
        height={image.height} 
        style={{...style}}
        className={`Image Image--${there?'complete':'loading'} ${className}`}
        onLoad={()=>this.setState({there:'complete'})}
        />
    );
  }
}