import React, { Component } from 'react';
import { Image } from '../components/Image.js';

export class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      indicator:{
        in:false,
        left: 100,
        top: 100,
        string: "Titel",
      }
    }
    this.setIndicator = this.setIndicator.bind(this);
  }
  
  setIndicator(state,event,string){
    if(state){
      this.setState({
        indicator:{
          in:state,
          string:string===null?this.state.indicator.string:string,
          left: event.clientX,
          top: event.clientY+(window.pageYOffset || document.documentElement.scrollTop)+30,
        }
      });
    }else{
      this.setState({
        indicator:{
          in:state,
          string:this.state.indicator.string,
          top: this.state.indicator.top,
          left: this.state.indicator.left,
        }
      });
    }
  }

  componentDidMount() {
    
  }
  
  render() {
    const {dimensions,zoom} = this.props;
    const {indicator} = this.state;

    const style = { 
      container:{
        paddingTop: dimensions.unit,
        position: "relative",
      },
      indicator:{
        position:"absolute",
        left:indicator.left,
        top:indicator.top,
        width: "auto",
        whiteSpace: "nowrap",
        transform: "translateX(-50%)",
      }
    };

    return (
      <div style={style.container} className={`${zoom.in?"Dashboard--zoomed":""}`}>

        { indicator.in && this.props.location.pathname === "/" ? <div className="Indicator lead" style={style.indicator}>{indicator.string}</div>:null}
        
        {this.props.data.projekte.map((projekt,index)=>
          <Item 
            {...this.props} 
            key={projekt.slug} 
            projekt={projekt} 
            index={index} 
            setIndicator={this.setIndicator} 
            />
          )}
      </div>
    );
  }

}







const Item = props => {
  const {projekt,dimensions,zoom} = props;
  
  const open = props.zoom.in && projekt.slug === zoom.slug;
  

  const handleItemClick = (event,slug,position) => {
    event.preventDefault();
    const domRect = event.currentTarget.getBoundingClientRect();
    props.setZoom({
      in:true,
      hidepage: true,
      position: position,
      slug: slug,
      itemTop: domRect.top,
      scrollTop: window.pageYOffset,
    });
    props.history.push(`/projekte/${slug}`);
  }


  let style = {
    container: {
      display: "block",
      width: dimensions.unit*3,
      height: dimensions.unit*4,
      position: "relative",
    },
    inner: {
      // width: open? dimensions.unit*5 : "100%",
    },
    image: {
      display: "block",
      top: 0,
      position: "absolute",
      height: "100%",
      width: "auto",
    }
  };

  switch (projekt.position){
    case 1:
      style.container.marginTop = -dimensions.unit;
      style.container.marginLeft = "auto";
      style.container.marginRight = "auto";

      style.inner.left = "50%";
      style.inner.transform = "translateX(-50%)";

      style.image.left = "50%";
      style.image.transform = "translateX(-50%)";

      break;
    case 2:
      style.container.marginTop = -dimensions.unit;
      style.container.marginRight = "auto";
      break;
    case 3:
      style.container.marginTop = -4*dimensions.unit;
      style.container.marginLeft = "auto";

      style.inner.right = 0;

      style.image.right = 0;
      break;
    default:
      break;
  }

  return (
    <a 
      href={`/projekte/${projekt.slug}`} 
      onClick={(event)=>{ handleItemClick(event,projekt.slug,projekt.position) }}
      className={`DashboardItem d-block DashboardItem-${projekt.position} ${open&&'DashboardItem--open'}`} 
      style={style.container}
      onMouseMove={(event)=>props.setIndicator(true,event,projekt.title)}
      onMouseLeave={(event)=>props.setIndicator(false,null,null)}
      >
      <div 
        className="DashboardItem__Inner" 
        style={style.inner}
        >
        <Image 
          image={projekt.vorschaubild}
          style={style.image}
          className="d-block h-100" 
          />
      </div>
    </a>
  );

}