import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Navigation } from './Navigation';
import { Zoom } from './Zoom';
import { Controller } from './Controller';
import { transDuration } from '../constants.js';
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group';
import { AppContext } from '../app-context';
import { Switch } from 'react-router-dom';
import np from 'nested-property';

import { PageContainer } from './PageContainer';


export default (props) => (
  <AppContext.Consumer>
    { (context) => <Router> 
      <Route path="/:section/:slug" children={ (route) => {
        const section = np.get( route.match,"params.section");
        if(
          route.location.pathname === "/" ||
          section === "projekte" ||
          section === "etcetera" ||
          section === "studio"
        ){
          return( <AppRouter {...context} {...route}/>);
        }else{
          return <RedirectHome />
        } 
      }}/> 
    </Router> }
  </AppContext.Consumer>
);



class AppRouter extends Component {
  
  componentDidMount() {
    if(np.get(this.props.match,"params.section")==="projekte"){
      this.fakeZoomParams();
    }
  }
 
  fakeZoomParams( ){
    const {data,location,dimensions,match} = this.props;
    if( np.get(match,"params.section") === "projekte" && np.get(match,"params.slug") !== this.props.zoom.slug  ){
      const {unit} = dimensions;
      const thisSlug = location.pathname.split("/")[2];
      const projekt = data.projekte.find(e=>e.slug === thisSlug);
      const blockIndex  = Math.floor( data.projekte.map(function(e) { return e.slug; }).indexOf(thisSlug)/3);
      const unterkante = blockIndex * 6*unit + ( projekt.position === 2 || projekt.position === 3 ? 7*unit : 4*unit );
      let scrollTop = 0;
      let itemTop = 0;
      if(unterkante > dimensions.wind.height){
        scrollTop = unterkante - dimensions.wind.height;
        itemTop = dimensions.wind.height - 4*unit;
      }else{
        itemTop = blockIndex * 6*unit + ( projekt.position === 2 || projekt.position === 3 ? 3*unit : 0 );
      }
      window.scrollTo(0,scrollTop);
      this.props.setZoom({
        in: true,
        hidepage: true,
        position: projekt.position,
        slug: projekt.slug,
        itemTop: itemTop,
        scrollTop: scrollTop,
      });
    }
  }

  render() {

    const {location,match} = this.props;   
    const {hidepage} = this.props.zoom;

    return (
      <React.Fragment>

        <Controller {...this.props} />
        <Navigation {...this.props} />

        <Transition 
          mountOnEnter 
          unmountOnExit 
          in={ location.pathname === "/" || np.get(match,"params.section") === "projekte" } 
          timeout={transDuration}
          >
          <Zoom {...this.props} /> 
        </Transition>

        <CSSTransition 
          in={location.pathname!=="/"} 
          timeout={transDuration} 
          mountOnEnter 
          unmountOnExit 
          onEntered={this.fakeZoomParams.bind(this)}
          classNames="slowfade">
          { state =>
            <div className={ `Page ${hidepage && (state==="entering"||state==="exiting") ? "hidepage" : null}` }>
              <TransitionGroup>
                <CSSTransition
                  key={this.props.location.key}
                  classNames="slowfade" 
                  timeout={transDuration}
                  onEntered={this.fakeZoomParams.bind(this)}
                  >
                    <Switch location={this.props.location}>
                      <Route exact path="/:section/:slug" render={({match})=>( <PageContainer {...this.props}/> )} />
                    </Switch>
                </CSSTransition>
              </TransitionGroup>
            </div>
          }
        </CSSTransition>

        <Route path={["/projekte","/studio","/etcetera"]} exact render={RedirectHome} />

      </React.Fragment>
    );
  }
}



const RedirectHome = ()=> <Redirect to="/" />
