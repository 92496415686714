import React from 'react';

export const Metadata = props => {

  const rows = [];
  
  for (var property in props.metadata) {
    if (props.metadata.hasOwnProperty(property)) {
      if(props.metadata[property].content){
        rows.push(
          <tr key={property}>
            <th scope="row">{props.metadata[property].title}</th>
            <td className="pl-3">{props.metadata[property].content}</td>
          </tr>
        );
      }
    }
  }

  const {weitereMetadaten} = props;
  weitereMetadaten?.forEach((row,i)=>{
        rows.push(
        <tr key={"row"+i}>
          <th scope="row">{row.title}</th>
          <td className="pl-3">{row.content}</td>
        </tr>
      );
    })
    
  return(
    <div className="Metadata">
      <table className="table">
        <tbody>
        {rows.map(r=>{return r})}
        </tbody>
      </table>
    </div>
  );

}