import React from 'react';
import Slick from "react-slick";

export class Slider extends React.Component {
  render() {
    const { slides, dimensions } = this.props;

    const style = {
      width: dimensions.wind.with,
      height: dimensions.wind.height - 2*dimensions.headerHeight - 1,
    }

    const settings = {
      dots: false,
      arrows: false,
    };

    return (
      <Slick {...settings} ref={c => (this.slider = c)}>
        {
          slides.map(bild=>
          <div 
            key={bild.urlDesktop} 
            className="m-Slide"
            >
            <div 
              className="m-SlideInner" 
              style={style}
              >
              <img 
                src={bild.urlDesktop} 
                alt={bild.title} 
                className="m-SlideImage"
                />
            </div>
          </div>
        )}
      </Slick>
    );
  }
}