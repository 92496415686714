export const calculateDimensions = () => {
  
  
  let wind = {
    width: document.documentElement.clientWidth || document.body.clientWidth,
    height: window.innerHeight,
  } 
  
  const unit = wind.width / 11;
  const rem = ( wind.width>=1600 ? 18 : 14 );

  let stage = {};


  // fit stage to height
  if( wind.width / (wind.height-14*rem) > 4/3 ){
    stage.width = ((wind.height-14*rem) / 3) * 4;
    stage.height = wind.height-14*rem;
  // fit stage to width
  }else{
    stage.width = wind.width;
    stage.height = wind.width/4*3;
  };




  const dimensions = {
      wind:wind, 
      rem:rem,
      stage:stage,
      unit: unit,
      factor: stage.height/4*3/(unit*3) ,
      headerHeight: rem*7 ,
    };

  return dimensions;

}