import React from 'react';

import eArr from '../static/e-arr.svg';
import wArr from '../static/w-arr.svg';
import wDArr from '../static/w-darr.svg';
import eDArr from '../static/e-darr.svg';

import eDoubleCursor from '../static/e-double-cursor.png';
import wDoubleCursor from '../static/w-double-cursor.png';


import eSingleCursor from '../static/e-single-cursor.png';
import wSingleCursor from '../static/w-single-cursor.png';


import navToggler from '../static/nav-toggler.svg';

export const Arrow = props => {
  const {type} = props;
  let Arrow;

  switch (type) {
    case "east":
      Arrow = <img src={eArr} alt="next" className="Icon" />;
      break;
    case "west":
      Arrow = <img src={wArr} alt="prev" className="Icon" />;
      break;
    case "east-double":
      Arrow = <img src={eDArr} alt="next" className="Icon" />;
      break;
    case "west-double":
      Arrow = <img src={wDArr} alt="prev" className="Icon" />;
      break;
    default:
      Arrow = null;
      break;
  }
  return Arrow;
}



export const NavToggler = props => {
  const style = {
    width: "200%",
    height: "100%",
    display: "block",
    transform: props.active ? "translate(-50%)" : "translate(0)" ,
  } 
  return <div className="Icon"><img src={navToggler} alt="prev" style={style} /></div>
};



export const arrowCursorStyles = {
  singleE:{
    cursor: `url("${eSingleCursor}") 28 28, e-resize`,
  },
  singleW:{
    cursor: `url("${wSingleCursor}") 28 28, w-resize`,
  },
  doubleE:{
    cursor: `url("${eDoubleCursor}") 28 28, e-resize`,
  },
  doubleW:{
    cursor: `url("${wDoubleCursor}") 28 28, w-resize`,
  },
}