import React from 'react';
import Slick from "react-slick";
import {arrowCursorStyles} from '../components/Arrows';


export class Slider extends React.Component {

  constructor(props){
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.setCaption = this.setCaption.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  
  previous() {
    this.slider.slickPrev();
  }

  setCaption(index){
    const { slides, } = this.props;
    this.props.setCaption( slides[index].caption );
  }

  render() {
    const { slides } = this.props;

    const settings = {
      dots: false,
      arrows: false,
      afterChange: (index)=>{ this.setCaption(index) },
      onInit: ()=>{ this.setCaption(0) }
    };

    return (
      <Slick {...settings} ref={c => (this.slider = c)}>

        {slides.map(bild=>
          <div key={bild.urlDesktop} className="Slide">
            <div className="Slide__Inner" style={{height:this.props.dimensions.stage.height, width:this.props.dimensions.stage.width+1 }}>
              <img 
                src={bild.urlDesktop} 
                alt={bild.title} 
                className="SlideImage"
                />
              {slides.length>1?
                <div 
                  onClick={this.previous} 
                  className="Slick_Arrow SlickArrow_Prev"
                  style={{...arrowCursorStyles.singleE}}
                  ></div>
                  :null}
              {slides.length>1?
                <div 
                  onClick={this.next} 
                  className="Slick_Arrow SlickArrow_Next" 
                  style={{...arrowCursorStyles.singleW}}
                  ></div>
                  :null}
            </div>
          </div>)}
      </Slick>
    );
  }
}