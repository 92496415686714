import React, { Component } from 'react';
import Hammer from 'react-hammerjs';
import {Link} from 'react-router-dom';


export class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      left: false,
    }

  }

  render() {
    const {dimensions} = this.props;
    
    const style = {
      container:{
        paddingTop: dimensions.unit,
        paddingBottom: dimensions.unit*2,
      },
    }
    
    return (
      <Hammer 
        // direction={'DIRECTION_ALL'}
        onSwipe={(e)=>{
          if(e.direction === 2){ this.setState({left:true}) } 
          if(e.direction === 4){ this.setState({left:false}) } 
        }} 
        >
        <div 
          className="m-DashboardContainer"
          onWheel={(e)=>{
            if(e.deltaX<-5){this.setState({left:false})}
            if(e.deltaX>5){this.setState({left:true})}
          }}
          >
          <div className={`m-DashboardInner ${ this.state.left ? "m-DashboardInner--left" : "" }`} style={style.container} >
              { this.props.data.projekte.map( (projekt,index) => 
                <MobileDashboardItem {...this.props} key={projekt.slug} projekt={projekt} index={index} />) }
          </div>
        </div>
      </Hammer>
    );
  }
}



const MobileDashboardItem = props => {
  const {projekt,dimensions} = props;
  const style = {
    item:{
      width: dimensions.unit*6,
      height: dimensions.unit*8,
    },
  }

  switch (projekt.position){
    case 1:
      style.item.marginTop = -dimensions.unit;
      style.item.marginLeft = "auto";
      style.item.marginRight = "auto";
      break;
    case 2:
      style.item.marginTop = -dimensions.unit;
      style.item.marginRight = "auto";

      break;
    case 3:
      style.item.marginTop = -8*dimensions.unit;
      style.item.marginLeft = "auto";
      break;
    default:
      break;
  }

  return (
    <Link to={`/projekte/${projekt.slug}`} className="m-DashboardItemContainer d-block no-decoration" style={style.item} >
      <div className="m-DashboardItemImageContainer">
        <img src={projekt.vorschaubild.urlMobile} alt="" className="m-DashboardItemImage d-block" />
      </div>
      <div className={`m-DashboardItemCaption ${( projekt.position !== 2 ? "pt-2" : "pt-2 pl-2" )}`}>{projekt.title}</div>
    </Link>
  );

}