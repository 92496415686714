import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Navigation } from './Navigation';
import { Dashboard } from './Dashboard.js';
import { AppContext } from '../app-context';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { transDuration } from '../constants.js';
import { PageContainer } from './PageContainer';
import np from 'nested-property';

export default (props) => (
  <AppContext.Consumer>
    { (context) => <Router> 
      <Route path="/:section/:slug" children={ (route) => {
        const section = np.get( route.match,"params.section");
        if(
          route.location.pathname === "/" ||
          section === "projekte" ||
          section === "etcetera" ||
          section === "studio"
        ){
          return( <AppRouter {...context} {...route}/>);
        }else{
          return <RedirectHome />
        } 
      }}/>  
    </Router> }
  </AppContext.Consumer>
);


class AppRouter extends Component {
  componentDidMount() {
    document.getElementsByTagName( 'html' )[0].setAttribute( 'class', '' );
  }
  render() {
    return (
      <React.Fragment>
      
        <Navigation {...this.props} />  

        <div className="m-Container">

         <CSSTransition
            in={this.props.location.pathname==="/"}
            classNames="slowfade" 
            timeout={transDuration}
            unmountOnExit
            mountOnEnter
            >
            <Dashboard {...this.props}/>
          </CSSTransition>

          <TransitionGroup component={null}>
            <CSSTransition
              key={this.props.location.key}
              classNames="slowfade" 
              timeout={transDuration}
              onEntered={ () => window.scrollTo( 0,0 ) }
              >
                <Switch location={this.props.location}>
                  <Route exact path="/:section/:slug" render={({match})=>( <PageContainer {...this.props} /> )} />
                </Switch>
            </CSSTransition>
          </TransitionGroup>

        </div>
        <Route path={["/projekte","/studio","/etcetera"]} exact render={RedirectHome} />
      </React.Fragment>
    );
  }
}


const RedirectHome = ()=> <Redirect to="/" />

