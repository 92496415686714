import React, { Component } from 'react';
import { Dashboard } from './Dashboard';
import { transDuration } from '../constants.js';

export class Zoom extends Component {
  render() {
    const {props} = this;
    const {zoom,dimensions} = props;
    const zoomCalcs = calculateZoom(zoom,dimensions,zoom);

    const slideStyle = {
        transition: `transform ${transDuration}ms ease, opacity 1000ms linear`,
        transformOrigin: `${zoomCalcs.originX}% ${zoomCalcs.originY}px`,
        transform: 
          props.zoom.in ? 
          `translateY(${zoomCalcs.translateY}px) scale(${dimensions.factor})` :
          `translateY(0px) scale(1)` ,
    }

    return (
      <div className="Zoom__Track w-100">
        <div className="Zoom__Slide" style={slideStyle}>
          <Dashboard {...props} />
        </div>
      </div>
    );
  }
}


const calculateZoom = (zoomParams,dimensions) => {
  
  let calcs = {
    translateY: 0,
    originX: 0,
    originY: zoomParams.scrollTop+zoomParams.itemTop ,
    offsetTop: -zoomParams.scrollTop,
  };

  let padTop;
  switch (zoomParams.position){
    case 1:
      calcs.originX = 50;
      padTop = 0;
      break;
    case 2: 
      calcs.originX = 0;
      padTop = dimensions.headerHeight;
      break;
    default: 
      calcs.originX = 100
      padTop = dimensions.headerHeight;
      break;
  }

  calcs.translateY = padTop-zoomParams.itemTop;

  return calcs;
}
