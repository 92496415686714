import React, { Component } from 'react';

import { Page } from './Page.js';
import { dataUrl } from '../constants.js';
import { Crash } from '../components/Crash';

const CRASH = "CRASH";  
const LOADING = "LOADING";
const COMPLETE = "COMPLETE";

export class Projekt extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      data: null,
      status: LOADING,
    }
  }
  
  componentDidMount() {
    
    const {id} = this.props;

    fetch(`${dataUrl}/projekte/${id}.json`)
    .then(async (resp)=>{
        console.log({resp})
        const body = await resp.json()
        console.log({body})
        this.setState({ 
          data:body, 
          status:COMPLETE 
        })
    }).catch(err=>{
      this.setState({ status:CRASH });
    });


  }

  render() {
    console.log(this.state.data)
    if(this.state.status !== CRASH ){
      return (
        <Page 
          {...this.props} 
          {...this.state.data}
          /> 
        );
    }else{
      return <Crash />
    }
  }
} 




