import React, { Component } from 'react';
import { Page } from './Page.js';
import { Projekt } from './Projekt.js';

export class PageContainer extends Component {
  
  constructor(props){
    super(props);
    this.state = initialState(props);
  }

  componentDidMount() {
    
  }
  
  render() {
    const {nextLink,prevLink,entry,section} = this.state; 
    if(section==="studio"){
      return ( <Page {...entry} {...this.props} position={2} nextLink={nextLink} prevLink={prevLink} section={section} /> );
    }else if(section==="etcetera"){
      return ( <Page {...entry} {...this.props} position={3} nextLink={nextLink} prevLink={prevLink} section={section} /> );
    }else if(section==="projekte"){
      return ( <Projekt {...entry} {...this.props} position={entry.position} nextLink={nextLink} prevLink={prevLink} section={section} /> );
    }else{
      return null
    }
  }
}




const initialState = (props)=>{
  const { data, match } = props;
  const entry = data[match.params.section].find( e => e.slug === match.params.slug);

  // determin links
  let searchTerm = entry.id,
  index = -1;
  for(let i = 0, len = data[match.params.section].length; i < len; i++) {
    if (data[match.params.section][i].id === searchTerm) {
      index = i;
      break;
    }
  }

  const prevLink = ( index-1<0 ? null : `/${match.params.section}/${data[match.params.section][index-1].slug}` );
  const nextLink = ( index+2>data[match.params.section].length ? null : `/${match.params.section}/${data[match.params.section][index+1].slug}` );

  return{
    entry,
    prevLink,
    nextLink,
    section:match.params.section,
  };
};
