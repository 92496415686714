import React, { Component } from 'react';
import {calculateDimensions} from './dimensions.js';
import axios from 'axios';
import {dataUrl,defaultZoomParams} from './constants.js';
import {AppContext} from './app-context';

import {CSSTransition} from 'react-transition-group';
import { transDuration } from './constants.js';

import RouterDesktop from './desktop/Router';
import RouterMobile from './mobile/Router';

import { Crash } from './components/Crash';
import Loader from './components/Loader';




export default class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: null,
      status: "loading",
      dimensions: calculateDimensions(),
      setDimensions: () => this.setState({ dimensions:calculateDimensions() }),
      zoom:defaultZoomParams,
      setZoom: (params)=>{ this.setState({ zoom: params }); },
      overlay: "",
      setOverlay: (overlay)=>{ this.setState({ overlay:overlay }); },
    }    
  }


  async componentDidMount() {

  

    const projekte = fetch(`${dataUrl}/projekte.json`);
    const studio = fetch(`${dataUrl}/studio.json`);
    const etcetera = fetch(`${dataUrl}/etcetera.json`);
    

    Promise.all([projekte, studio, etcetera]).then(
      async (res) => {
        const projekte = await res[0].json();
        const studio = await res[1].json();
        const etcetera = await res[2].json();
      this.setState({ 
        status: "complete",
        data: { 
          projekte:projekte.data,
          studio: studio.data,
          etcetera: etcetera.data,
        }
      })
    })


    window.addEventListener( "resize" , ()=>{
      if( this.state.dimensions.wind.width !== window.innerWidth || Math.abs(this.state.dimensions.wind.height - window.innerHeight) > 40 ){
        this.setState({ dimensions: calculateDimensions(), })} 
      }
    );
  }
  
  render() {
    const {status, dimensions} = this.state;
    const Router = ( dimensions.wind.width>768 ? <RouterDesktop/> : <RouterMobile/> )

    return (
      <AppContext.Provider value={this.state}>

          { status === "crash" ? <Crash/> : null }
          { status === "loading" ? <Loader/> : null }
          <CSSTransition 
            in={status === "complete"} 
            timeout={transDuration} 
            unmountOnExit 
            mountOnEnter 
            classNames="slowfade">
            <div>{Router}</div>
            </CSSTransition>

      </AppContext.Provider>
    );
  }
}
