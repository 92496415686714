import React from 'react';
import { calculateDimensions } from './dimensions';
import { defaultZoomParams } from './constants';


export const AppContext = React.createContext({     
  data: null,
  status: "loading",
  dimensions: calculateDimensions(),
  zoom: defaultZoomParams,
  setZoom:()=>{},
  overlay: "",
  setOverlay: ()=>{},
});