import React, { Component } from 'react';

import {Link} from 'react-router-dom';
import np from 'nested-property';


import { transDuration } from '../constants.js';
import { CSSTransition } from 'react-transition-group';


export class Navigation extends Component {

  constructor(props){
    super(props);
    this.state = {
      overlay:null,
    }
    this.handleClick = (overlay)=>{
      let nextOverlay = overlay;
      if(this.props.overlay === overlay){
        nextOverlay = "";
      }
      this.setState({overlay:overlay});
      this.props.setOverlay(nextOverlay);
    }
  }

  render() {
    const open = this.props.overlay === "projekte" || this.props.overlay === "studio" || this.props.overlay === "etcetera";
    const {overlay} = this.props;
    const section = np.get(this.props,"match.params.section");
    return (
      <React.Fragment>

        <NavigationLinks handleClick={this.handleClick} overlay={overlay} section={section} />

        <CSSTransition
          classNames="fade"
          in={open}
          unmountOnExit
          mountOnEnter
          timeout={transDuration}>
          <NavContainer {...this.props} overlay={this.state.overlay}/>
        </CSSTransition>

      </React.Fragment>
    );
  }

}



const NavigationLinks = (props) => {
  
  const { section,overlay } = props;

  const projekteClass = ( overlay === "projekte" || ( overlay === "" && section === "projekte" )  ? "link-active" : null ) ,
        studioClass =   ( overlay === "studio"   || ( overlay === "" && section === "studio" )    ? "link-active" : null ) ,
        etceteraClass = ( overlay === "etcetera" || ( overlay === "" && section === "etcetera" )  ? "link-active" : null ) ;

  return (        
    <nav className="lead">

      <Link 
        to="/" 
        className={`lead no-decoration NavBtn NavBtn__Home`}>
        Lemmen Mazzei
      </Link>

      <button
        to="#projekte" 
        onClick={()=>{props.handleClick("projekte")}}
        className={`button-link clickable NavBtn NavBtn__Projekte ${ projekteClass }`}>
        Projekte
      </button>
      
      <button
        to="#studio" 
        onClick={()=>{props.handleClick("studio")}}
        className={`button-link clickable NavBtn NavBtn__Studio ${ studioClass }`}>
        Studio
      </button>

      <button
        to="#etcetera" 
        onClick={()=>{props.handleClick("etcetera")}}
        className={`button-link clickable NavBtn NavBtn__EtCetera ${ etceteraClass }`}>
        Et cetera
      </button>

    </nav>
    );
}; 




const NavContainer = (props)=>(
  <div className="NavContainer">
    <div className={`NavContainer__Inner bg-${props.overlay} h-100`}>
      <div className="NavContainer__List px-3">
        {props.data[props.overlay].map( e => <NavRow key={e.slug} overlay={props.overlay} {...e} /> )}
      </div>
    </div>
  </div>
);




const NavRow  = (props)=>(
  <Link to={"/"+props.overlay+"/"+props.slug} className="d-flex justify-content-between no-decoration">
      <span className="lead">{props.title}</span>
      {props.overlay==="projekte"&&
        <span className="" style={{ lineHeight: 1, paddingTop:".9rem" }}>
          <span className="pl-3">{props.metadata.auftragsart}</span> 
          <span className="pl-3">{props.metadata.auftragskategorie}</span> 
          <span className="pl-3">{props.metadata.jahr}</span>
        </span>
      }
      {props.overlay==="etcetera"&&
        <span className="" style={{ lineHeight: 1, paddingTop:".9rem" }}>
          {props.categories.map((c,i)=>{return `${c}${props.categories.length !==i+1?", ":""}`})}
        </span>
      }
  </Link>
);


