import React, { Component } from 'react';
import Hammer from 'react-hammerjs';

import { Link } from 'react-router-dom';
import { Metadata } from '../components/Metadata.js'
import { CSSTransition } from 'react-transition-group';
import eDArr from '../static/e-darr.svg';
import { Slider } from './Slider.js';

export class Page extends Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
    }
    this.setOverlay = this.setOverlay.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
    this.scrollContainer = React.createRef();
  }

  setOverlay( bool = !this.state.open , method ){
    this.setState({open:bool});
  }

  handleWheel(e){
    // const { slug,overlay } = this.props; 
    const { open } = this.state; 
    if( e.deltaY>0 && !open){
      this.setOverlay(true);
    }else if( e.deltaY<0 && open ){
      if(this.scrollContainer.current.scrollTop === 0){
        this.setOverlay(false);
      }
    }
  }

  handleTouch(e){
    if(e.direction === 8){
      this.setOverlay(true)
    } else if ( e.direction === 16 && this.scrollContainer.current.scrollTop === 0 ){ 
      this.setOverlay(false) 
    }
  }

  render() {

    const { title, dimensions, metadata, intro, main, bilder, weitereMetadaten } = this.props;
    const { open } = this.state;

    const style = {
      page:{
      },
      body: {
        paddingTop: dimensions.headerHeight,
        paddingBottom: dimensions.headerHeight,
      },
      tease:{
        height: !open?"1rem":0,
      },
      overlay:{
        maxHeight: dimensions.wind.height-dimensions.headerHeight,
      }
    }

    return (
      <Hammer 
        direction={'DIRECTION_ALL'}
        onPan={this.handleTouch} 
        onSwipe={this.handleTouch} 
        options={{
          touchAction:'auto',
          domEvents: true,
        }}
        >
          <div className="m-Page" onWheel={this.handleWheel.bind(this)}>

                <div className="m-Page__Content">

                    <div className="m-Page__Body" style={style.body}>
                        { bilder && <Slider {...this.props} slides={bilder}/> }
                    </div>

                    <div className="m-Page__Footer clickable no-decoration" >
                        <h3 onClick={()=>this.setOverlay(true)}>{title}</h3>
                        { this.props.nextLink != null && 
                          <Link to={this.props.nextLink} className="m-PageNextArr">
                            <img src={eDArr} alt="next" className="m-Arrow"/>
                          </Link> 
                        }
                        <div style={style.tease} className="m-PageTease bg-mobile" />
                    </div>

                </div>

              <CSSTransition 
                in={open} 
                timeout={600} 
                classNames="swipe-from-bottom" 
                onExited={()=>this.scrollContainer.current.scrollTo(0,0)}>
                <div className={`m-OverlayContainer`} style={style.overlay} onClick={()=>this.setOverlay(false)} >
                  <div 
                    className={`m-OverlayInner pt-3 bg-mobile`} 
                    ref={this.scrollContainer} 
                    style={style.overlay}
                    >
                    <div className="container-fluid">
                      <div className="row">
                        <div 
                          className="col-12 col-md-6" 
                          dangerouslySetInnerHTML={{__html:intro}} />
                        <div className="col-12 col-md-6" >
                          <div dangerouslySetInnerHTML={{__html:main}} />
                          {(metadata || weitereMetadaten) && <Metadata metadata={metadata} weitereMetadaten={weitereMetadaten} /> }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>

          </div>
      </Hammer>

    );
  }
}

