import React, { Component } from 'react';
import { Slider } from './Slider';
import {arrowCursorStyles} from '../components/Arrows';
import { Link } from 'react-router-dom';
import Hammer from 'react-hammerjs';
import { Metadata } from '../components/Metadata.js'


export class Page extends Component {

  render() {
    return (
      <React.Fragment>
        <Content {...this.props} />
      </React.Fragment>
    );
  }
}


class Content extends Component {

  constructor(props){
    super(props);
    this.state = {
      caption:"",
      tease: false,
    }
    this.setCaption = caption => {
      this.setState({caption:caption,});
    }
    this.setOverlay = this.setOverlay.bind(this);
    this.scrollContainer = React.createRef();
  }

  componentDidMount() {
  }

  handleWheel(e){
    const { slug,overlay } = this.props; 
    if( e.deltaY>0 && overlay !== slug){
      this.setOverlay(true);
    }else if( e.deltaY<0 && overlay !== ""){
      if(this.scrollContainer.current.scrollTop === 0){
        this.setOverlay(false);
      }
    }
  }

  setOverlay(bool){
    if(bool===true){
      this.props.setOverlay(this.props.slug);
    }else{
      this.props.setOverlay("")
    }
  }

  render(){

    const { bilder,position,dimensions,overlay,section,metadata,weitereMetadaten,intro,main,slug } = this.props; 

    const { tease,caption } = this.state;
    const open = overlay === slug;

    const style = {
      stage:{
        width: this.props.dimensions.stage.width,
        height: this.props.dimensions.stage.height,
      },
      footer:{
        height:this.props.dimensions.headerHeight,
      },
      overlay:{
        paddingTop: dimensions.headerHeight ,
      },
      overlayinner:{
        paddingBottom: dimensions.headerHeight,
        maxHeight: dimensions.wind.height-dimensions.headerHeight,
        transform: !open && `translateY(-${dimensions.headerHeight})`,
      }
    };



    return (
      <Hammer 
        direction={'DIRECTION_ALL'}
        onPan={(e)=>{
          if(e.direction === 8){this.setOverlay(true)}
          else if( e.direction === 16 && this.scrollContainer.current.scrollTop === 0 ){ this.setOverlay(false) }
        }} 
        onSwipe={(e)=>{ 
          if(e.direction === 8){ this.setOverlay(true) }
          else if( e.direction === 16 && this.scrollContainer.current.scrollTop === 0 ){ this.setOverlay(false) }  
        }} 
        options={{
          touchAction:'auto',
          domEvents: true,
        }}
        >


        <div 
          className={`Page__Content Page__Content--pos-${this.props.position}`} 
          onWheel={this.handleWheel.bind(this)}
          >


            { 
              this.props.prevLink != null && 
              <Link 
                to={this.props.prevLink} 
                className="PageArrow PageArrow-prev d-block" 
                style={{ ...arrowCursorStyles.doubleE}} />
            }

            { 
              this.props.prevLink != null && 
              <Link 
                to={this.props.prevLink} 
                className="PageArrowTop PageArrow-prev d-block" 
                style={{ ...arrowCursorStyles.doubleE}} /> 
            }

            { 
              this.props.nextLink != null && 
              <Link 
                to={this.props.nextLink} 
                className="PageArrow PageArrow-next d-block" 
                style={{ ...arrowCursorStyles.doubleW}} />
            }

            { 
              this.props.nextLink != null && 
              <Link 
                to={this.props.nextLink} 
                className="PageArrowTop PageArrow-next d-block" 
                style={{ ...arrowCursorStyles.doubleW}} />
            }


          { bilder &&
          <div className={`Page__Stage`} style={style.stage}>
             <Slider {...this.props} slides={bilder} position={position} setCaption={this.setCaption} />
          </div> }
        

          <div 
            className="Page__Footer d-flex flex-column justify-content-end clickable" 
            style={style.footer}
            onClick={()=>this.setOverlay(true)}
            onMouseEnter={()=>this.setState({ tease:true })}
            onMouseLeave={()=>this.setState({ tease:false })}
            >
            <div className="Page__Footer-Caption text-center">{caption}</div> 
            <div className="Page__Footer-Title lead text-center">{this.props.title}</div> 
            <div className={`Overlay__Tease ${ tease && !open ?"Overlay__Tease--open":""} bg-${section}`} />
          </div>


          <div 
            className={`Overlay Overlay--${open?"open":"closed"} ${tease?"Overlay--tease":""}`} 
            style={style.overlay} 
            onClick={()=>this.setOverlay(false)}
            >
            <div 
              className={`Overlay__Inner pt-3 bg-${section}`} 
              style={style.overlayinner}
              ref={this.scrollContainer}
              >

              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6" dangerouslySetInnerHTML={{__html:intro}}/>
                  <div className="col-12 col-md-6" >
                    <div dangerouslySetInnerHTML={{__html:main}} />
                    {(metadata || weitereMetadaten) && <Metadata metadata={metadata} weitereMetadaten={weitereMetadaten} /> }
                  </div>
                </div>
              </div>

            </div>
          </div>
        

        </div>
      </Hammer>
    );
  }
}